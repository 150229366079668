import _get from 'lodash/get';

import {
    compareListingsDrawerClick as reaxlCompareListingsDrawerClick,
    inventoryCertifiedTileClick as reaxlInventoryCertifiedTileClick,
    inventoryClick as reaxlInventoryClick,
    inventoryImpressions as reaxlInventoryImpressions,
    inventoryListingViewEllipsesClick as reaxlInventoryListingViewEllipsesClick,
    inventoryMediaViewerClick as reaxlInventoryMediaViewerClick,
    inventoryProductClick as reaxlInventoryProductClick,
    inventoryProductImpressions as reaxlInventoryProductImpressions,
    inventorySave as reaxlInventorySave,
    inventoryVehicleConditionHighlightsClick as reaxlInventoryVehicleConditionHighlightsClick,
    inventoryVideoCompleted as reaxlInventoryVideoCompleted,
    inventoryVideoMilestones as reaxlInventoryVideoMilestones,
    inventoryVideoPlay as reaxlInventoryVideoPlay,
    mediaGalleryNdsCTAClick as reaxlMediaGalleryNdsCTAClick,
    mediaTabClick as reaxlMediaTabClick,
    mediaViewerPagination as reaxlMediaViewerPagination,
    shareClick as reaxlShareClick,
    viewAllMediaClick as reaxlViewAllMediaClick,
} from 'reaxl-analytics-handlers';

import { userDuck } from '@/ducks';

import { srpFiltersDuck } from '@/ducks/srp';
import { vdpResultsDuck } from '@/ducks/vdp';

export const inventoryCertifiedTileClick = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);

    return reaxlInventoryCertifiedTileClick({
        ...metadata,
        data: {
            ...data,
            inventoryId: activeInventory.id,
            eventSource: 'vehicle',
        },
        userDuck,
    }, option);
};

export const inventoryClick = (metadata, option) => {
    const { data, state } = metadata;
    const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);

    return reaxlInventoryClick({
        ...metadata,
        data: {
            ...data,
            eventSource: 'vehicle',
            pixallData: {
                ...data.pixallData,
                filterValues,
            },
        },
        userDuck,
    }, option);
};

export const inventoryImpressions = (metadata, option) => reaxlInventoryImpressions({
    ...metadata,
    userDuck,
}, option);

export const inventoryProductClick = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlInventoryProductClick({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
        userDuck,
    }, option);
};

export const inventorySave = (metadata, option) => reaxlInventorySave({
    ...metadata,
    userDuck,
}, option);

export const compareListingsDrawerClick = (metadata, option) => {
    const { data, state } = metadata;
    const { pixallData } = data;
    const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
    const zipCode = _get(userDuck.selectors.getZip(state));

    return reaxlCompareListingsDrawerClick({
        ...metadata,
        pixallData: {
            ...pixallData,
            filterValues,
            zipCode,
        },
    }, option);
};

export const inventoryListingViewEllipsesClick = (metadata, option) => reaxlInventoryListingViewEllipsesClick({
    ...metadata,
    userDuck,
}, option);

export const inventoryProductImpressions = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlInventoryProductImpressions({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const inventoryMediaViewerClick = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlInventoryMediaViewerClick({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const viewAllMediaClick = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlViewAllMediaClick({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const mediaViewerPagination = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlMediaViewerPagination({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const mediaTabClick = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlMediaTabClick({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const inventoryVehicleConditionHighlightsClick = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
    return reaxlInventoryVehicleConditionHighlightsClick({
        ...metadata,
        data: {
            filterValues,
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const inventoryVideoPlay = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlInventoryVideoPlay({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const inventoryVideoCompleted = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlInventoryVideoCompleted({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const inventoryVideoMilestones = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlInventoryVideoMilestones({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const mediaGalleryNdsCTAClick = (metadata, option) => {
    const { data, state } = metadata;
    const activeInventory = vdpResultsDuck.selectors.getVdpActiveInventory(state);
    return reaxlMediaGalleryNdsCTAClick({
        ...metadata,
        data: {
            inventoryId: activeInventory?.id,
            ...data,
        },
    }, option);
};

export const shareClick = (metadata, option) => {
    // TODO on SRP GA is missing CD's 100 - 160
    const { data } = metadata;
    return reaxlShareClick({
        ...metadata,
        data: {
            ...data,
            inventoryId: data?.id,
        },
    }, option);
};

export default {
    // InventoryListingFooter from atc-ui need userDuck from this event
    inventoryListingViewEllipsesClick,
};
