import _get from 'lodash/get';

import {
    inventorySearch,
    numRecordsDropDownClick,
    ownerImpressions,
    paginationClick,
    articleCarouselClick as reaxlArticleCarouselClick,
    articlesCarouselImpression as reaxlArticlesCarouselImpression,
    cpoFilterTileClick as reaxlCpoFilterTileClick,
    filterClick as reaxlFilterClick,
    inventoryCertifiedTileClick as reaxlInventoryCertifiedTileClick,
    inventoryClick as reaxlInventoryClick,
    inventoryImpressions as reaxlInventoryImpressions,
    inventorySave as reaxlInventorySave,
    ownerLogoClick as reaxlOwnerLogoClick,
    ownerShowHideInventory as reaxlOwnerShowHideInventory,
    page as reaxlPage,
    psxPrivateSellerPlacementClick as reaxlPsxPrivateSellerPlacementClick,
    snapshotPaymentDetailsClick as reaxlSnapshotPaymentDetailsClick,
    sortDropDownClick,
} from 'reaxl-analytics-handlers';

import { getAlphaListingType } from '@/utilities/getAlphaTaggingType';

import {
    inventoryDuck,
    ownersDuck,
    userDuck,
} from '@/ducks';

import {
    srpFiltersDuck,
    srpNewCarBoostDuck,
    srpPaginationDuck,
    srpResultsDuck,
} from '@/ducks/srp';

import AlphaModule from '@/modules/AlphaModule';

const srpImpType = 'srpi';
const srpLabel = 'search-results-page';

export const alphaImpression = (metadata, option) => {
    const { state } = metadata;
    const alpha = AlphaModule.duck.selectors.getDuckState(state);
    const isOwnerAlpha = !AlphaModule.duck.selectors.isListingAlpha(state);
    const isAlphaMarket = AlphaModule.duck.selectors.isAlphaMarket(state);
    const inventory = inventoryDuck.selectors.getDuckState(state);
    const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
    const showcaseListingId = alpha.listings[0];

    if (showcaseListingId && Object.prototype.hasOwnProperty.call(inventory, showcaseListingId) && !isOwnerAlpha) {
        return reaxlInventoryImpressions({
            ...metadata,
            data: {
                cmp: 'alp',
                pixallData: {
                    filterValues,
                    label: srpLabel,
                    listingType: getAlphaListingType(isAlphaMarket, false),
                    impressionType: getAlphaListingType(isAlphaMarket, false),
                    listingCode: showcaseListingId,
                },
                selectedItemIds: [showcaseListingId],
            },
            userDuck,
        }, option);
    }

    // need to fetch separately if alpha has no inventory
    const alphaOwnerId = alpha.owner || '';

    if (!showcaseListingId || isOwnerAlpha) {
        return reaxlInventoryImpressions({
            ...metadata,
            data: {
                cmp: 'dealer_alp',
                pixallData: {
                    eventSource: 'dealership',
                    filterValues,
                    label: 'dealer-alpha',
                    listingType: getAlphaListingType(isAlphaMarket, true),
                },
                selectedItemIds: [alphaOwnerId],
            },
        }, option);
    }

    return {};
};

export const articlesCarouselImpression = (metadata = {}, option) => {
    const { state } = metadata;
    const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
    return reaxlArticlesCarouselImpression({
        ...metadata,
        data: { filterValues },
    }, option);
};

export const articleCarouselClick = (metadata = {}, option) => {
    const { data, state } = metadata;
    const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
    return reaxlArticleCarouselClick({
        ...metadata,
        data: {
            ...data,
            filterValues,
        },
    }, option);
};

export const filterClick = (metadata = {}, option) => reaxlFilterClick({
    ...metadata,
    filterDuck: srpFiltersDuck,
}, option);

export const ownerShowHideInventory = (metadata, option) => reaxlOwnerShowHideInventory({
    ...metadata,
    userDuck,
}, option);

export const inventoryImpressions = (metadata, option) => {
    const { data, state } = metadata;
    const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);

    const pixallData = {
        filterValues,
        isCarousel: data && data.isCarousel,
        impressionType: srpImpType,
        listingType: data && data.cmp,
        label: data.label || srpLabel,
        ...data?.pixallData,
    };

    // eslint-disable-next-line default-case
    switch (data.cmp) {
        case 'sim_v_lstg_':
            pixallData.label = 'sim_v_lstg';
            pixallData.listingType = 'similar';
            pixallData.impressionType = 'similar';
            break;
        case 'goodgreatprice':
        case 'interested':
        case 'numviewed':
        case 'reduced':
            pixallData.label = 'fyc_srl';
            break;
        case 'sim':
        case 'incentives':
        case 'price-reduced':
        case 'newly-listed':
        case 'number-viewed':
            pixallData.label = 'highlight';
            pixallData.listingType = 'highlight';
            pixallData.impressionType = 'highlight';
            break;
        case 'ime':
            pixallData.label = 'ime';
            break;
        case 'still_interested':
            pixallData.label = 'still-interested::spotlight';
            pixallData.listingType = 'interested';
            break;

    }

    return reaxlInventoryImpressions({
        ...metadata,
        data: {
            ...data,
            cmp: data && data.cmp,
            pixallData,
            selectedItemIds: data && data.selectedItemIds,
        },
        resultsDuck: data && data.selectedItemIds ? undefined : srpResultsDuck,
        userDuck,
    }, option);
};

export const supplementalImpressions = (metadata, option) => {
    const { data, state } = metadata;
    const { rank, selectedItemIds } = data;

    const cmp = 'sup';

    const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
    return reaxlInventoryImpressions({
        ...metadata,
        data: {
            cmp,
            pixallData: {
                filterValues,
                isCarousel: false,
                impressionType: srpImpType,
                label: srpLabel,
                listingType: cmp,
                rank,
            },
            selectedItemIds,
        },
        userDuck,
    }, option);
};

export const spotlightImpressions = (metadata, option) => {

    const { state } = metadata;

    let cmp = 'spv_lstg_';
    let rank = 1;
    const selectedItemIds = metadata.data.activeInventory;
    if (metadata.data.spotlightOffset) {
        cmp += metadata.data.spotlightOffset;
        rank = metadata.data.spotlightOffset;
    }

    const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
    return reaxlInventoryImpressions({
        ...metadata,
        data: {
            cmp,
            pixallData: {
                filterValues,
                isCarousel: false,
                impressionType: srpImpType,
                label: srpLabel,
                listingType: 'spv',
                rank,
            },
            selectedItemIds,
        },
        userDuck,
    }, option);
};

export const newCarBoostImpressions = (metadata, option) => {
    const { state } = metadata;
    const boostListings = srpNewCarBoostDuck.selectors.getAvailableInventory(state);

    const cmp = 'ncb_v_lstg';
    const selectedItemIds = boostListings.map(({ id }) => id);

    const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
    return reaxlInventoryImpressions({
        ...metadata,
        data: {
            cmp,
            pixallData: {
                filterValues,
                isCarousel: false,
                impressionType: srpImpType,
                label: 'fyc_srl',
                listingType: 'ncb',
            },
            selectedItemIds,
        },
        userDuck,
    }, option);
};

export const vehicleList = (metadata, option) => {
    const { state } = metadata;

    const selectedOwnerIds = [];
    const activeListings = srpResultsDuck.selectors.getActiveInventory(state);
    activeListings.map((listing) => {
        const { ownerId } = listing;
        if (!selectedOwnerIds.includes(ownerId)) {
            selectedOwnerIds.push(ownerId);
        }
    });

    option = {
        ...option,
        disableBirf: true,
    };

    return selectedOwnerIds.length ? ownerImpressions({
        ...metadata,
        data: {
            pixallData: {
                label: srpLabel,
                isList: 'true',
            },
            ownerIds: selectedOwnerIds,
        },
        ownersDuck,
        userDuck,
    }, option) : {};
};

export const page = (metadata, option) => {
    const { data, dataLayer, state } = metadata;
    const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
    const { stats: statFilterValue = {} } = srpResultsDuck.selectors.getDuckState(state);

    const makeCode = _get(filterValues, 'makeCodeList[0]', null);
    let modelCode = '';
    if (makeCode) {
        const model = `${makeCode === 'BMW' ? 'seriesCodeList|' : 'modelCodeList|'}${makeCode}`;
        modelCode = _get(filterValues, model, 'Any Model');
    }
    modelCode = modelCode.length > 0 ? modelCode : 'null';

    dataLayer.search.filters = {
        ...dataLayer.search.filters,
        endYear: statFilterValue.endYear || '',
        maxPrice: statFilterValue.maxPrice || '',
        minPrice: statFilterValue.minPrice || '',
        searchRadius: statFilterValue.searchRadius || 'rad',
        startYear: statFilterValue.startYear || '',
        zip: statFilterValue.zip || 'zip',
    };

    return reaxlPage({
        ...metadata,
        data: {
            ...data,
            modelCode: modelCode.toString(),
            pageType: 'vlp',
        },
        filterDuck: srpFiltersDuck,
        userDuck,
    }, option);
};

export const cpoFilterTileClick = (metadata = {}, option) => {
    const { data, state } = metadata;
    const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
    return reaxlCpoFilterTileClick({
        ...metadata,
        data: {
            ...data,
            filterValues,
        },
    }, option);
};

export const keywordSearchSubmit = (metadata, option) => {
    const keywordPhrases = _get(metadata, 'data.codes.keywordPhrases', null) || _get(metadata, 'data.name', '');
    return inventorySearch({
        ...metadata,
        data: {
            ...metadata.data,
            isKeywordSearch: true,
            keywordPhrases,
        },
    }, option);
};

export const inventoryCertifiedTileClick = (metadata, option) => reaxlInventoryCertifiedTileClick({
    ...metadata,
    userDuck,
}, option);

export const inventoryClick = (metadata, option) => {
    const { data, state } = metadata;
    const pixallData = { ...data.pixallData };
    const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);

    if (data.par === 'sim_v_lstg') {
        pixallData.listingType = 'similar';
    }

    if (data.par === 'interested_spotlight') {
        pixallData.listingType = 'interested';
    }

    return reaxlInventoryClick({
        ...metadata,
        data: {
            ...data,
            pixallData: {
                ...pixallData,
                filterValues,
            },
        },
        userDuck,
    }, option);
};

export const inventorySave = (metadata, option) => {
    const { data } = metadata;
    const pixallData = { ...data.pixallData };

    if (data.par === 'sim_v_lstg' && !pixallData.listingType) {
        pixallData.listingType = 'similar';
    }

    return reaxlInventorySave({
        ...metadata,
        data: {
            ...data,
            pixallData,
        },
        userDuck,
    }, option);
};

export const snapshotPaymentDetailsClick = (metadata, option) => reaxlSnapshotPaymentDetailsClick({
    ...metadata,
    userDuck,
}, option);

export const ownerLogoClick = (metadata, option) => reaxlOwnerLogoClick({
    ...metadata,
    userDuck,
}, option);

export const srpSortDropDownClick = (metadata, option) => sortDropDownClick({
    ...metadata,
    filterDuck: srpFiltersDuck,
    paginationDuck: srpPaginationDuck,
    resultsDuck: srpResultsDuck,
    userDuck,
}, option);

export const srpNumRecordsDropDownClick = (metadata, option) => numRecordsDropDownClick({
    ...metadata,
    filterDuck: srpFiltersDuck,
    paginationDuck: srpPaginationDuck,
    resultsDuck: srpResultsDuck,
    userDuck,
}, option);

export const srpPaginationClick = (metadata, option) => paginationClick({
    ...metadata,
    filterDuck: srpFiltersDuck,
    paginationDuck: srpPaginationDuck,
    resultsDuck: srpResultsDuck,
    userDuck,
}, option);

export const psxPrivateSellerPlacementClick = (metadata = {}, option) => {
    const { data, state } = metadata;
    const filterValues = srpFiltersDuck.selectors.getFiltersValues(state);
    return reaxlPsxPrivateSellerPlacementClick({
        ...metadata,
        data: {
            ...data,
            filterValues,
        },
    }, option);
};

export default {
    page,
};
