import { clickTypes } from 'reaxl-analytics-handlers';

const getAlphaListingType = (isAlphaMarket, isOwnerAlpha) => {
    if (isAlphaMarket) {
        return isOwnerAlpha ? 'alpm-dlr' : 'alpm';
    }

    return isOwnerAlpha ? 'alp-dlr' : 'alp';
};

const getAlphaClickType = (isAlphaMarket, isOwnerAlpha) => {
    if (isAlphaMarket) {
        return isOwnerAlpha ? clickTypes.DEALER_ALPHA_MARKET : clickTypes.VEHICLE_ALPHA_MARKET;
    }

    return isOwnerAlpha ? clickTypes.ALPHA_DEALER : clickTypes.ALPHA;
};

export { getAlphaListingType, getAlphaClickType };
